import React, { ReactElement, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'

import loadable from '@loadable/component'
import { CircularProgress } from '@material-ui/core'

const BikeVoting = loadable(() => import('@campaigns/bike-voting'))
const BikeVotingIntermot = loadable(
  () => import('@campaigns/bike-voting-intermot-winner')
)

const BrakingDistanceCalculator = loadable(
  () => import('@campaigns/bremswegrechner')
)
const HassknechtsWorld = loadable(() => import('@campaigns/hassknechts-welt'))
const EScooterQuiz = loadable(() => import('@campaigns/e-scooter-quiz'))
const InternationalSignQuiz = loadable(
  () => import('@campaigns/internationales-schilder-quiz')
)
const PkwBikeQuiz = loadable(() => import('@campaigns/pkw-fahrrad-quiz'))
const TimelineOfTrafficSafety = loadable(
  () => import('@campaigns/timeline-of-traffic-safety')
)
const AccidenceAtlas = loadable(() => import('@campaigns/unfallatlas'))
const PerspectivesOfSadness = loadable(
  () => import('@campaigns/perspektiven-der-betroffenheit')
)
const ShockMoments = loadable(() => import('@campaigns/schockmomente'))
const WayBackToLife = loadable(
  () => import('@campaigns/wege-zurueck-ins-leben')
)
const DrivingLicensePersonlized = loadable(
  () => import('@campaigns/fahrpruefer-personalisierbar')
)
const AgainstDistraction = loadable(
  () => import('@campaigns/mitmachaktion-gege-ablenkung')
)
const TimeLine = loadable(() => import('@campaigns/timeline'))
const PerspectiveSwitch = loadable(() => import('@campaigns/perpsektivwechsel'))

const AutobahnPlakate2022 = loadable(
  () => import('@campaigns/autobahnplakate2022')
)

const NoAnswer2023 = loadable(() => import('@campaigns/noAnswer2023'))

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '110vh',
  },
  loading: {
    position: 'absolute',
    top: '200px',
    left: '50%',
  },
  filler: {
    top: '0',
    height: '100vh',
    position: 'sticky',
    backgroundColor: 'rgb(0, 0, 0)',
    zIndex: '1000',
  },
}))

export interface CampaignPageProps {
  pagecontext: RvG.PageHelpers.PageContext
  children?: React.ReactChildren
  data: {
    contentfulCampaignPage: {
      identifier: string
      titleNavigation: string
      teaserCopy: RvG.Contentful.BasicRichTextType
      teaserImage?: RvG.Contentful.IAsset
    }
  }
}

export default function CampaignPage({
  data,
}: CampaignPageProps): ReactElement {
  const campaign = data.contentfulCampaignPage
  const { identifier, titleNavigation } = campaign
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (identifier === 'NoAnswer2023') {
      setTimeout(() => {
        setIsLoading(true)
      }, 1000)
    }
  }, [])

  function renderCampaign() {
    switch (identifier) {
      case 'bikevoting':
        return <BikeVoting />
      case 'bikevoting22':
        return <BikeVotingIntermot />
      case 'bremswegrechner':
        return <BrakingDistanceCalculator />
      case 'hassknechts-welt':
        return <HassknechtsWorld />
      case 'e-scooter-quiz':
        return <EScooterQuiz />
      case 'internationales-schilder-quiz':
        return <InternationalSignQuiz />
      case 'pkw-fahrrad-quiz':
        return <PkwBikeQuiz />
      case 'timeline-der-verkehrssicherheit':
        return <TimelineOfTrafficSafety />
      case 'unfallatlas':
        return <AccidenceAtlas />
      case 'perspektive-der-betroffenheit':
        return <PerspectivesOfSadness />
      case 'schockmomente':
        return <ShockMoments />
      case 'wege-zurueck-ins-leben':
        return <WayBackToLife />
      case 'fahrpruefer-personalisierbar':
        return <DrivingLicensePersonlized />
      case 'mitmachaktion-gege-ablenkung':
        return <AgainstDistraction />
      case 'perspektivwechsel':
        return <PerspectiveSwitch />
      case 'timeline':
        return <TimeLine />
      case 'autobahnplakate2022':
        return <AutobahnPlakate2022 />
      case 'NoAnswer2023':
        return (
          <>
            {!isLoading && (
              <div className={classes.filler}>
                <CircularProgress className={classes.loading} />
              </div>
            )}
            <NoAnswer2023 id="noAnswerPage" />
          </>
        )
      default:
        return <div>Hello Campaign: {titleNavigation}</div>
    }
  }

  return <section className={classes.root}>{renderCampaign()}</section>
}

export const pageQuery = graphql`
  query CampaignPageByIdentifier($id: String!, $locale: String!) {
    contentfulCampaignPage(id: { eq: $id }, node_locale: { eq: $locale }) {
      titleNavigation
      identifier
    }
  }
`
